exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-venir-tsx": () => import("./../../../src/pages/a-venir.tsx" /* webpackChunkName: "component---src-pages-a-venir-tsx" */),
  "component---src-pages-actualites-index-tsx": () => import("./../../../src/pages/actualites/index.tsx" /* webpackChunkName: "component---src-pages-actualites-index-tsx" */),
  "component---src-pages-annonces-immo-id-tsx": () => import("./../../../src/pages/annonces/{Immo.id}.tsx" /* webpackChunkName: "component---src-pages-annonces-immo-id-tsx" */),
  "component---src-pages-annonces-index-tsx": () => import("./../../../src/pages/annonces/index.tsx" /* webpackChunkName: "component---src-pages-annonces-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-sanity-legal-slug-current-tsx": () => import("./../../../src/pages/legal/{SanityLegal.slug__current}.tsx" /* webpackChunkName: "component---src-pages-legal-sanity-legal-slug-current-tsx" */),
  "component---src-pages-services-sanity-service-slug-current-tsx": () => import("./../../../src/pages/services/{SanityService.slug__current}.tsx" /* webpackChunkName: "component---src-pages-services-sanity-service-slug-current-tsx" */),
  "component---src-pages-tarifs-tsx": () => import("./../../../src/pages/tarifs.tsx" /* webpackChunkName: "component---src-pages-tarifs-tsx" */)
}

